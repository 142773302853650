import EmptySpace from "@components/empty-space/component";
import FullBleed from "@components/full-bleed";
import Layout from "@components/layout";
import Newsletter from "@components/newsletter/newsletter-component";
import PrivacidadeIntroComponent from "@sections/privacidade-intro/privacidade-intro-component";
import React from "react";
import Seo from "@components/seo";

export default function Privacidade() {
  return (
    <Layout isHeroAvailable={false}>
      <EmptySpace desktop={{ margin: 220 }} mobile={{ margin: 110 }} />

      <PrivacidadeIntroComponent />

      <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 60 }} />

      <FullBleed>
        <Newsletter />
      </FullBleed>
      <Seo
        title="TreeTree2 - Privacidade"
        description="Consulte a política de privacidade do site do TreeTree2."
      />
    </Layout>
  );
}
